/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import React from "react";
import Link, { LinkProps } from "next/link";
import { useRouter } from "next/router";
import { useCountry, useLanguage } from "hooks";

export type LinkWithLocaleProps = LinkProps;

export const LinkWithLocale: React.FC<LinkWithLocaleProps> = ({
  children,
  href,
  ...rest
}) => {
  const router = useRouter();
  const country = useCountry();
  const language = useLanguage();

  let newHref = href || router.asPath;

  if (language) {
    if (typeof href === "object") {
      newHref = {
        ...href,
        pathname: `/${country}/${language}${href.pathname}`,
      };
    } else {
      newHref = newHref
        ? `/${country}/${language}${newHref}`
        : router.pathname
            .replace("[language]", language as unknown as string)
            .replace("[country]", country as unknown as string);

      if (href?.indexOf("http") === 0) {
        newHref = href;
      }
    }
  }

  return (
    <Link href={newHref} {...rest}>
      {children}
    </Link>
  );
};

export default LinkWithLocale;
